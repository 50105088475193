import React from 'react'
import { StaticQuery, graphql,Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'


const OurWork = ({location}) => (
  <Layout  location={location}>
    <SEO title="Web Design Sacramento" />
    <StaticQuery
    query={graphql`
      query ServicesQuery {
        allContentfulService(sort: {fields: createdAt})  {
            edges {
              node {
                id
                slug
                metaTitle
                title
              }
            }
          }
      }
    `}
    render={data => { 
      return (
        <div className="container mx-auto px-6 py-12">  
        <h1 className="text-3xl md:text-4xl px-2">Services</h1>
        <div className="flex flex-wrap ">

{data.allContentfulService.edges.map((item,i)=>(
          <div className="p-2 w-1/2 md:w-1/3">
              <Link to={`/${item.node.slug}`}  className="hover:shadow-lg shadow-soft bg-white py-6 md:py-12  rounded items-center justify-center flex">
            <div className="w-32 text-6xl font-bold opacity-50 text-gray-300 text-center absolute z-10">{item.node.title.charAt(0)}</div>

         <span className="block rounded p-4 z-20 text-lg md:text-xl font-semibold">{item.node.title}</span>
</Link></div>
    
          ))}
           </div>
        </div>
          
             
      )
    }}
  />
  </Layout>
)

export default OurWork
